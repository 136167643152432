<template>
    <main class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3">
                <div class="container">
                    <div class="grid align-items-center">
                        <div v-if="!isSubPage" class="col-fixed ">
                            <Button @click="$router.go(-1)" label="" className="p-button p-button-text "
                                icon="pi pi-arrow-left" />
                        </div>
                        <div class="col ">
                            <div class="">
                                <div class="flex align-items-center ">
                                    <div>
                                        <div class="text-2xl text-primary font-bold">Payment Instructions</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section ">
            <div class="container">
                <div class="grid ">
                    <div class="md:col-9 sm:col-12 comp-grid">
                        <div>
                            <template v-if="pageReady">
                                <div class="grid">
                                    <div class="col-12">
                                        <div v-html="item.payment_instructions"></div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="loading">
                                <div class="p-3 text-center">
                                    <ProgressSpinner style="width:50px;height:50px" />
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>
<script setup>
import { toRefs, onMounted } from 'vue';
import { useApp } from '@/composables/app.js';
import { useViewPage } from '@/composables/viewpage.js';
const props = defineProps({
    id: [String, Number],
    primaryKey: {
        type: String,
        default: 'info_id',
    },
    pageName: {
        type: String,
        default: 'info',
    },
    routeName: {
        type: String,
        default: 'infoinstructions',
    },
    apiPath: {
        type: String,
        default: 'info/instructions',
    },
    editButton: {
        type: Boolean,
        default: true,
    },
    deleteButton: {
        type: Boolean,
        default: true,
    },
    exportButton: {
        type: Boolean,
        default: true,
    },
    titleBeforeDelete: {
        type: String,
        default: "Delete record",
    },
    msgBeforeDelete: {
        type: String,
        default: "Are you sure you want to delete this record?",
    },
    msgAfterDelete: {
        type: String,
        default: "Record deleted successfully",
    },
    showHeader: {
        type: Boolean,
        default: true,
    },
    showFooter: {
        type: Boolean,
        default: true,
    },
    isSubPage: {
        type: Boolean,
        default: false,
    }
});
const app = useApp(props);
const page = useViewPage(props); // page hook
const { item, loading, pageReady } = toRefs(page.state);
const { apiUrl, currentRecord } = page.computedProps
const { load, deleteItem, exportPage, moveToNextRecord, moveToPreviousRecord } = page.methods;
function getActionMenuModel(data) {
    return []
}
onMounted(() => {
    const pageTitle = "Payment Instructions";
    app.setPageTitle(props.routeName, pageTitle); // set browser page title
    load();
});
</script>
<style scoped></style>
